<template>
  <div class="d-flex align-center justify-center">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import {downloadFile, showNotification} from '../js/helpers';

export default {
  name: 'QrCode',

  computed: {

  },

  props: [
    'text', 'eventProvider'
  ],

  watch: {
    text() {
      this.generateQR(this.text);
    }
  },

  created() {
    this.eventProvider.$on('downloadQRCode', (args) => {
      QRCode.toDataURL(
          this.text,
          this.options,
          (error, url) => {
            if (error) {
              return console.error(error);
            }

            downloadFile({ src: url, fileName: 'qr-code.png', isBlank: true });
            showNotification({ title: 'Generated!' });
          }
      );
    });
  },

  mounted() {
    this.generateQR(this.text);
  },

  methods: {
    generateQR(text) {
      if (!this.$refs.canvas) { return }

      QRCode.toCanvas(
          this.$refs.canvas,
          text,
          this.options,
          (error) => {
            if (error) {
              console.error(error);
            }
          }
      );
    }
  },

  data: () => ({
    options: {
      width: 300,
      color: {
        light: '#fff',
        dark: '#000'
      }
    }
  }),
}
</script>

<style lang="scss">

</style>
