export const passwordRules = [
  v => v.length >= 6 || 'Password must be at least 6 characters',
  v => /.*[0-9].*/.test(v) || 'Password must contain at least one number',
];

export const emailRules = [
  v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
];

export const requiredRules = [
  v => !! v || 'This field is required.'
];

export const asinRules = [
  v => /([A-Z0-9]{10})/.test(v) || 'Invalid ASIN.'
];

const numbersReg = /^\d+$/;
export const itemIdRules = [
  v => numbersReg.test(v) || 'Invalid Item ID.'
];