<template>
  <v-dialog
      :value="show"
      width="500"
      @input="handleModalVisibilityChanged"
  >
    <v-card outlined>
      <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
        <div class="position-relative width-100 ">
          <span class="d-block pr-6">Take Your generated QR Code</span>
          <v-icon id="qrCodeClose" @click="toggleQrModal(false)" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
        </div>
      </v-card-title>

      <div class="py-6 px-7">
        <div>
          <QRCode v-if="url" :text="url" :eventProvider="eventProvider" />
        </div>

        <v-card-text class=" text--secondary">
          Click the button below to download the generated QR Code
        </v-card-text>

        <v-row>
          <v-col>
            <v-btn
                color="cyan accent-4 ma-auto" block
                @click="downloadQRCode"
                dark
                class="custom--btn" depressed
                height="40"
            >
              Download
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import QRCode from './QrCode';
import {mapMutations, mapState} from "vuex";
import Vue from "vue";

export default {
  name: 'ConfirmModal',

  computed: {
    ...mapState('qrcode', ['url', 'show']),
  },

  components: {
    QRCode
  },

  props: [

  ],

  watch: {
    'show': {
      handler: function (value, oldValue) {
        if (value) {
          this.$nextTick(function() {
            const closeBtn = document.getElementById('qrCodeClose');
            if (closeBtn) {
              setTimeout(() => closeBtn.blur(), 0);
            }
          });
        }
      }
    }
  },

  created() {

  },

  mounted() {

  },

  methods: {
    ...mapMutations('qrcode', ['toggleQrModal']),
    downloadQRCode() {
      this.eventProvider.$emit('downloadQRCode');
    },
    handleModalVisibilityChanged(e) {
      this.toggleQrModal(false);
    }
  },

  data: () => ({
    eventProvider: new Vue(),
  }),
}
</script>

<style lang="scss">

</style>
