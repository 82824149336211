<template>
  <div class="popover--width">
    <v-popover
        placement="top" popoverBaseClass="v-tooltip__content tooltip popover" container="#app" trigger="hover" >
      <v-text-field
          value="walmart.com"
          readonly
          persistent-placeholder
          outlined
          dense
          solo
          class="font-size-15"
          flat
          hide-details="auto"
      >
        <template v-slot:append>
          <v-icon
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
      </v-text-field>

      <template slot="popover">
        <span>For Walmart is available only walmart.com marketplace</span>
      </template>
    </v-popover>
  </div>
</template>

<script>

export default {
  name: 'WalmartMarketplaceOption',

  computed: {

  },

  props: [
    'forInput'
  ],

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>
