<template>
  <v-row align="center" justify="start" dense class="flex-nowrap">
    <v-col>
      <v-text-field
          :value="campaign.fullUrl"
          solo
          outlined
          dense
          flat
          readonly
          class=" input--prepend-btn font-size-12 color--inherit grey--text text--darken-1 cursor-pointer"
          :class="{'input--height-28': !large}"
          hide-details
          :height="large ? '40' : '28'"
          @click="openLink({ src: campaign.fullUrlWithProtocol, isBlank: true })"
      >
        <template v-slot:prepend-inner>
          <v-btn class="mr-1 custom--btn custom--border" :height="large ? 40 : 28" outlined depressed :small="large ? false : true" @click="getQRCode(campaign)">QR Code</v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="auto">
      <v-btn class="custom--btn" color="cyan accent-4" :height="large ? 40 : 28" outlined depressed :small="large ? false : true" @click="copyTextToClipboard(campaign.fullUrlWithProtocol)">Copy</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {copyTextToClipboard, openLink} from '../js/helpers';
import {mapMutations} from "vuex";

export default {
  name: 'RotatorFinalUrl',

  computed: {

  },

  props: ['campaign', 'large'],

  watch: {

  },

  created() {

  },

  beforeMount() {
    this.copyTextToClipboard = copyTextToClipboard;
    this.openLink = openLink;
  },

  mounted() {

  },

  methods: {
    ...mapMutations('qrcode', ['setQrUrl']),
    getQRCode(campaign) {
      const { fullUrlWithProtocol } = campaign;
      if (!fullUrlWithProtocol) { return }

      this.setQrUrl(fullUrlWithProtocol)
    }
  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>
